// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const BoatHaulingPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      boatHauling: file(relativePath: { eq: "boat-hauling.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Boat Hauling">
      <PageTitle title="BOAT HAULING" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.boatHauling.childImageSharp.fluid}
          caption="Bay Sails Marine truck hauling a sailboat"
          displayCaption
          maxWidth={500}
        />
      </ImageBlock>

      <Typography paragraph>
        Bay Sails Marine offers hauling service for Provincetown, Truro,
        Wellfleet, Eastham, and Orleans. Hauling to and from other locations is
        available by quote. Please <Link to="/directions">stop in</Link> or{" "}
        <Link to="/contact">contact us</Link> for more information.
      </Typography>
    </Layout>
  )
}

export default BoatHaulingPage
